import React, {Component} from 'react';
import {connect} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';
import {addToCart, addToCompare, addToWishlist} from '../../../actions';
import {getVisibleproducts} from '../../../services';
import ProductListItem from "./product-list-item";
import {apiCall} from "../../../helpers/networkHelper";
import {PRODUCTS} from "../../../utils/endpoints";
import {message} from 'antd';
import {empty} from "../../../helpers/generalHelper";
import styles from './ProductListing.module.scss';

class ProductListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            currentProductCount: 0,
            limit: 8,
            hasMoreItems: true,
            products: [],
            productsLoaded: false,
            totalProductCount: 0
        };
    }

    componentDidMount() {
        this.loadProducts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.search !== this.props.search ||
            prevProps.categoryId !== this.props.categoryId ||
            prevProps.categorySubId !== this.props.categorySubId ||
            prevProps.sort !== this.props.sort ||
            prevProps.sale !== this.props.sale
        ) {
            this.loadProducts();
        }
    }

    loadProducts = (page=1, limit=8, cb=()=>{}) => {
        const {categoryId, categorySubId, sort, search, sale} = this.props;
        apiCall(
            PRODUCTS + `?page=${page}&limit=${limit}&category_id=${categoryId??''}&category_sub_id=${categorySubId??''}&sort=${sort??'NEWEST'}&search=${search??''}&sale=${sale??''}`,
            'get',
            null,
            (res) => {
                const products = res.data.products.map(row => {
                    const retailPrice = parseInt(row.retail_price);
                    const netPrice = parseInt(row.net_price);
                    // const directDiscPercent = !empty(row.direct_discount)?parseFloat(row.direct_discount.direct_disc_percent):0;
                    // const directDiscNominal = !empty(row.direct_discount)?parseInt(row.direct_discount.direct_disc_nominal):0;
                    // const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;
                    const grandPrice = netPrice;

                    let coverImage = row.product_images[0]?.image_url;
                    row.product_images.find(productImage => {
                       if(productImage.image_type === 'COVER'){
                           if(!empty(productImage.image_url)){
                               coverImage = productImage.image_url;
                           }
                       }

                       return productImage;
                    });

                    return {
                        ...row,
                        id: row.id,
                        name: row.product_name,
                        price: row.retail_price,
                        salePrice: row.net_price,
                        discount: Math.floor(row.net_price / row.retail_price),
                        retailPrice,
                        netPrice,
                        // directDiscPercent,
                        // directDiscNominal,
                        grandPrice,
                        coverImage: coverImage,
                        pictures: row.product_images.map(product_image => product_image.image_url),
                        shortDetails: row.short_description,
                        description: row.detailed_description,
                        stock: 100,
                        new: false,
                        sale: false,
                        // category: "women"
                        // colors: (2) ["gray", "orange"]
                        size: row.sizes.map(size => size.size_code),
                        // tags: (2) ["caprese", "puma"]
                        rating: 5,
                        variants: row.sizes.map(size => {
                            return {
                                size: size.size_code,
                                images: !empty(row.product_images) ? row.product_images[0].image_url : ''
                            };
                        })
                    };
                });

                const currentProductCount = [...this.state.products, ...products].length;
                const totalProductCount = res.data.product_count;
                const hasMoreItems = !(currentProductCount >= totalProductCount);

                this.props.setProductCount(totalProductCount);

                this.setState({
                    products: [...this.state.products, ...products],
                    productsLoaded: true,
                    currentProductCount,
                    totalProductCount,
                    hasMoreItems
                }, cb);
            },
            (res) => {
                message.error('Fail to retrieve products.');
            }
        );
    };

    fetchMoreItems = async () => {
        const {page, products, totalProductCount, limit} = this.state;

        if (products.length >= totalProductCount /*this.props.products.length*/) {
            await this.setState({hasMoreItems: false});
        }else{
            await this.loadProducts(page+1, limit, () => {
                this.setState({
                    page: page+1
                });
            });
        }
    };

    render() {
        const {addToCart, symbol, addToWishlist, addToCompare} = this.props;
        const {products} = this.state;

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {products.length > 0 ?
                            <InfiniteScroll
                                scrollThreshold={0.6}
                                dataLength={this.state.currentProductCount} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"/>}
                                endMessage={
                                    <p className={`seen-cls seen-it-cls ${styles.resultEnd}`}>
                                        All products has been loaded
                                    </p>
                                }
                            >
                            <div className="row">
                                {products.slice(0, this.state.currentProductCount).map((product, index) =>
                                    <div
                                        // className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`}
                                        className={`col-xl-4 col-md-6 col-grid-box`}
                                        key={index}>
                                        <ProductListItem product={product}
                                                         symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={addToCart}
                                                         key={index}
                                        />
                                    </div>)
                                }
                            </div>
                            </InfiniteScroll>
                            /*<InfiniteScroll
                                dataLength={this.state.currentProductCount} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                    {products.slice(0, this.state.currentProductCount).map((product, index) =>
                                        <div
                                            className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`}
                                            key={index}>
                                            <ProductListItem product={product}
                                                             symbol={symbol}
                                                             onAddToCompareClicked={() => addToCompare(product)}
                                                             onAddToWishlistClicked={() => addToWishlist(product)}
                                                             onAddToCartClicked={addToCart}
                                                             key={index}
                                            />
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>*/
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    {/*<img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                         className="img-fluid mb-4"/>
                                    <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                    <p>Please check if you have misspelt something or try searching with other
                                        words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                        shopping</Link>*/}
                                    {this.state.productsLoaded && (
                                        <>
                                            {/*<img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                                 className="img-fluid mb-4"
                                                 alt="Product not found"
                                            />*/}
                                            <h3 style={{marginBottom: 30}}>Coming Soon!</h3>
                                            {/*<p style={{marginBottom: 30}}>Please check if you have misspelled something or try searching with other
                                                words.</p>*/}
                                            {/*<a href={`/products`} className="btn btn-solid">continue
                                                shopping</a>*/}
                                            <a href={`/products`} className="btn btn-solid">continue
                                                shopping</a>
                                        </>
                                    )}

                                    {!this.state.productsLoaded && (
                                        <p>
                                            Loading...
                                        </p>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(ProductListing)