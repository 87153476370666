import {
    ADD_TO_CART,
    CLOSE_CART,
    DECREMENT_QTY,
    OPEN_CART,
    REMOVE_FROM_CART,
    RETRIEVE_CART
} from "../constants/ActionTypes";
import {empty} from "../helpers/generalHelper";
import {PROMO_TYPE_GROUP, PROMO_TYPE_PRODUCT, PROMO_TYPE_SUB_GROUP} from "../utils/constants";

const constructProductBundles = (promos, products) => {
    let productBundles = [];
    promos.map(promo => {
        const eligibleProducts = products.map(product => {
            return promo.promo_bundling_products.map(promo_bundling_product => {
                if(promo_bundling_product.bundling_type === PROMO_TYPE_GROUP
                    && product.group_id === promo_bundling_product.m_group_id){
                    return product;
                }

                if(promo_bundling_product.bundling_type === PROMO_TYPE_SUB_GROUP
                    && product.sub_group_id === promo_bundling_product.m_sub_group_id){
                    return product;
                }

                if(promo_bundling_product.bundling_type === PROMO_TYPE_PRODUCT
                    && product.id === promo_bundling_product.product_detail_id){
                    return product;
                }

                return false;
            });
        });

        const eligibleProductsCount = eligibleProducts.reduce((previousValue, currentValue) => {
            return currentValue.qty + previousValue;
        }, 0);

        if(eligibleProductsCount >= promo.bundling_item_count){
            let bundleProducts = [];

            eligibleProducts.map(product => {
                if(product.qty <= (promo.bundling_item_count - bundleProducts.length)){
                    bundleProducts = bundleProducts.concat({
                        ...product,
                        qty: product.qty
                    });

                    if(bundleProducts.length === promo.bundling_item_count){
                        productBundles.concat({
                            ...promo,
                            bundleProducts
                        });
                        bundleProducts = [];
                    }
                }else{
                    const bundleLoop = Math.floor(product.qty / promo.bundling_item_count);
                    for(let i=0; i<bundleLoop; i++){
                        bundleProducts = bundleProducts.concat({
                            ...product,
                            qty: product.qty
                        });
                        productBundles.concat({
                            ...promo,
                            bundleProducts
                        });
                        bundleProducts = [];
                    }
                }
                return product;
            });
        }

        return promo;
    });

    return productBundles;
};

const markBundledQtyOnProducts = (productBundles, cart) => {
    return cart.map(product => {
        let bundledQty = 0;
        productBundles.map(bundle => {
            return bundle.map(bundleProduct => {
                if(bundleProduct.id === product.id){
                    bundledQty += bundleProduct.qty;
                }

                return bundleProduct;
            });
        });

        return {
            ...product,
            bundledQty
        };
    });
};

export default function cartReducer(state = {
    onlineCart: [],
    cart: [],
    productBundles: [],
    cartOpened: false
}, action) {
    switch (action.type) {
        case OPEN_CART:
            return {
                ...state,
                cartOpened: true
            };

        case CLOSE_CART:
            return {
                ...state,
                cartOpened: false
            };
        case RETRIEVE_CART:
            return {
                ...state,
                onlineCart: action.cart
            };

        case ADD_TO_CART:
            const productId = action.product.id;
            const promos = action.promos;

            // const products = state.cart;

            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === productId) {
                        cartAcc.push({
                            ...product,
                            qty: empty(product.qty) ? 1 : product.qty+1,
                            sum: ( product.grandPrice * (empty(product.qty)?1:product.qty+1) )
                        }); // Increment qty
                    } else {
                        cartAcc.push(product);
                    }

                    return cartAcc;
                }, []);

                const productBundles = constructProductBundles(promos, cart);


                const markedCart = markBundledQtyOnProducts(productBundles, cart);

                return {
                    ...state,
                    cart: markedCart,
                    productBundles
                };
            }

            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        sum: (action.product.grandPrice * action.qty)
                    }
                ]
            };

        case DECREMENT_QTY:
            
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({ ...product, qty: product.qty-1, sum: (product.price*product.discount/100)*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: action.product.price*action.qty }] }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }

        default:
    }
    return state;
}
