import React, {Component} from 'react';
import {connect} from 'react-redux'
import 'react-input-range/lib/css/index.css';
import {SlideToggle} from 'react-slide-toggle';
import styles from './filter.module.scss';
import {getBrands, getColors, getMinMaxPrice} from '../../../services';
import {filterBrand, filterColor, filterPrice} from '../../../actions'
import {apiCall} from "../../../helpers/networkHelper";
import {CATEGORIES} from "../../../utils/endpoints";
import {empty, getUrlParam} from "../../../helpers/generalHelper";
import {message} from "antd";

class Filter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFilter: false,
            categories: [],
            filterGroup: null,
            filterSubgroup: null,
            filterNewArrival: false,
            category_id: getUrlParam('category_id'),
            category_sub_id: getUrlParam('category_sub_id')
        }
    }

    componentDidMount() {
        this.loadCategories();
    }

    loadCategories = () => {
        apiCall(
            CATEGORIES,
            'get',
            null,
            (res) => {
                console.log(res.data.categories);
                this.setState({
                    categories: res.data.categories
                });
            },
            (res) => {
                message.error('Fail to retrieve categories.');
            }
        );
    };

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    };

    render (){
        const {categories} = this.state;

        let highlightedCategoryId = parseInt(this.state.category_id) || 0;
        let highlightedSubCategoryId = parseInt(this.state.category_sub_id) || 0;

        return (
                <div className="collection-filter-block" style={{border: 0}}>
                    {/*brand filter start*/}
                    <div className="collection-mobile-back">
                        <span className="filter-back" onClick={(e) => this.closeFilter(e)} >
                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block">
                                {/*<h3 className="collapse-block-title" onClick={onToggle}>categories</h3>*/}
                                <div className="collection-collapse-block-content"  ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        <ul className={styles.categoryList}>
                                            {!empty(categories) && categories.map(category => {
                                                return (<li key={`Group${category.id}`}>
                                                    <a href={`/products?category_id=${category.id}`} className={`${highlightedCategoryId === category.id ? styles.highlighted : ''}`}>{category.name}</a>

                                                    {!empty(category.category_subs) && (
                                                        <React.Fragment>
                                                            <ul>
                                                                {category.category_subs.map(category_sub => (
                                                                    <li key={`SubGroup${category_sub.id}`} style={{paddingLeft: 0}}>
                                                                        <a
                                                                           href={`/products?category_sub_id=${category_sub.id}`}
                                                                           className={`${highlightedSubCategoryId === category_sub.id ? styles.highlighted : ''}`}
                                                                        >{category_sub.name}</a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    )}
                                                </li>);
                                            })}
                                            {/*<li><a href="#">MAN</a></li>
                                            <li><a href="#">WOMAN</a></li>
                                            <li><a href="#">NEW ARRIVAL</a></li>
                                            <li><a href="#">SALE</a></li>*/}
                                        </ul>
                                        {/*<div className="custom-control custom-checkbox collection-filter-checkbox">*/}
                                        {/*    <a href="#">MAN</a>*/}
                                        {/*</div>*/}
                                        {/*{this.props.brands.map((brand, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input type="checkbox" onClick={(e) => this.clickBrandHandle(e,filteredBrands)} value={brand} defaultChecked={filteredBrands.includes(brand)? true : false}  className="custom-control-input" id={brand} />
                                                    <label className="custom-control-label"
                                                           htmlFor={brand}>{brand}</label>
                                                </div> )
                                        })}*/}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                </div>
        )
    }
}


const mapStateToProps = state => ({
    brands: getBrands(state.data.products),
    colors: getColors(state.data.products),
    prices: getMinMaxPrice(state.data.products),
    filters: state.filters
})

export default connect(
    mapStateToProps,
    { filterBrand, filterColor, filterPrice }
)(Filter);