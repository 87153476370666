import React, {Component} from 'react';
import {Input, message, Select, Spin} from "antd";
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {
    MASTER_CITY,
    MASTER_PROVINCE,
    MASTER_SUBDISTRICT,
    USER_ADDRESS,
    USER_ADDRESS_SET_PRIMARY
} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import AccountMenu from "./atoms/AccountMenu";
import styles from "./Checkout.module.scss";

class AccountShippingAddress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            showAddressForm: false,
            addressFormMode: 'new',
            editAddressId: null,

            user_addresses: [],
            selectedUserAddressId: null,
            provinces: [],
            cities: [],
            subdistricts: [],

            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            recipient_zip: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',
        };

        this.emptyAddressForm = {
            addressFormMode: 'new',
            editAddressId: null,
            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            recipient_zip: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',
        };
    }

    componentDidMount() {
        this.loadUserAddresses();
        this.loadProvinces();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    loadProvinces = () => {
        apiCall(
            MASTER_PROVINCE,
            'get',
            null,
            res => {
                this.setState({
                    provinces: res.data.province_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        )
    };

    loadCities = () => {
        this.showLoader('Loading cities..');
        apiCall(
            MASTER_CITY + `?province_id=${this.state.province_id}`,
            'get',
            null,
            res => {
                this.setState({
                    cities: res.data.city_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadSubdistrict = () => {
        this.showLoader('Loading subdistricts..');
        apiCall(
            MASTER_SUBDISTRICT + `?city_id=${this.state.city_id}`,
            'get',
            null,
            res => {
                this.setState({
                    subdistricts: res.data.subdistrict_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadUserAddresses = () => {
        this.showLoader('Loading your addresses..');
        apiCall(
            USER_ADDRESS,
            'get',
            null,
            res => {
                this.setState({
                    user_addresses: res.data.user_addresses
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    onChangeAddressLabel = e => {
        this.setState({address_label: e.target.value});
    };

    onChangeRecipientName = e => {
        this.setState({recipient_name: e.target.value});
    };

    onChangeRecipientPhone = e => {
        this.setState({recipient_phone: e.target.value});
    };

    onChangeRecipientZip = e => {
        this.setState({recipient_zip: e.target.value});
    };

    onChangeAddressStreet = e => {
        this.setState({address_street: e.target.value});
    };

    onChangeAddressNotes = e => {
        this.setState({address_notes: e.target.value});
    };

    onChangeProvince = (value, option) => {
        this.setState({
            province_id: value
        }, () => {
            this.loadCities();
        });
    };

    onChangeCity = (value, option) => {
        this.setState({
            city_id: value
        }, () => {
            this.loadSubdistrict();
        });
    };

    onChangeSubdistrict = (value, option) => {
        this.setState({
            subdistrict_id: value
        });
    };

    onAddNewAddressBtnClicked = () => {
        this.setState({
            showAddressForm: true,
            ...this.emptyAddressForm
        });
    };

    onCancelNewAddressForm = () => {
        this.setState({
            showAddressForm: false,
            ...this.emptyAddressForm
        });
    };

    onSubmitNewAddress = () => {
        const {
            address_label,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes
        } = this.state;
        let valid_form = true;

        if(empty(address_label)){
            message.error('Address label is required.');
            valid_form = false;
        }
        if(empty(recipient_name)){
            message.error('Recipient name is required.');
            valid_form = false;
        }
        if(empty(recipient_phone)){
            message.error('Recipient phone is required.');
            valid_form = false;
        }
        if(empty(recipient_zip)){
            message.error('Recipient zipcode is required.');
            valid_form = false;
        }
        if(empty(address_street)){
            message.error('Address street is required.');
            valid_form = false;
        }
        if(empty(province_id)){
            message.error('Province must be chosen.');
            valid_form = false;
        }
        if(empty(city_id)){
            message.error('City must be chosen');
            valid_form = false;
        }
        if(empty(subdistrict_id)){
            message.error('Subdistrict must be chosen.');
            valid_form = false;
        }

        if(valid_form){
            this.showLoader('Saving your new address..');

            apiCall(
                USER_ADDRESS,
                'post',
                {
                    address_label,
                    recipient_name,
                    recipient_phone,
                    recipient_zip,
                    address_street,
                    province_id,
                    city_id,
                    subdistrict_id,
                    address_notes
                },
                res => {
                    message.success(res.data.message);
                    this.loadUserAddresses();
                    this.setState({
                        showAddressForm: false,
                        ...this.emptyAddressForm
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.hideLoader();
                }
            );
        }
    };

    editAddress = (address) => {
        this.setState({
            showAddressForm: true,
            addressFormMode: 'edit',
            editAddressId: address.id,
            address_label: address.label,
            recipient_name: address.recipient_name,
            recipient_phone: address.recipient_phone,
            recipient_zip: address.recipient_zip,
            address_street: address.address_street,
            province_id: address.province_id,
            city_id: address.city_id,
            subdistrict_id: address.subdistrict_id,
            address_notes: address.notes
        }, () => {
            this.loadCities();
            this.loadSubdistrict();
        });
    }

    onSubmitEditAddress = () => {
        const {
            editAddressId,
            address_label,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes
        } = this.state;
        let valid_form = true;

        if(empty(address_label)){
            message.error('Address label is required.');
            valid_form = false;
        }
        if(empty(recipient_name)){
            message.error('Recipient name is required.');
            valid_form = false;
        }
        if(empty(recipient_phone)){
            message.error('Recipient phone is required.');
            valid_form = false;
        }
        if(empty(recipient_zip)){
            message.error('Recipient zipcode is required.');
            valid_form = false;
        }
        if(empty(address_street)){
            message.error('Address street is required.');
            valid_form = false;
        }
        if(empty(province_id)){
            message.error('Province must be chosen.');
            valid_form = false;
        }
        if(empty(city_id)){
            message.error('City must be chosen');
            valid_form = false;
        }
        if(empty(subdistrict_id)){
            message.error('Subdistrict must be chosen.');
            valid_form = false;
        }

        if(valid_form){
            this.showLoader('Saving your new address..');

            apiCall(
                USER_ADDRESS,
                'put',
                {
                    user_address_id: editAddressId,
                    address_label,
                    recipient_name,
                    recipient_phone,
                    recipient_zip,
                    address_street,
                    province_id,
                    city_id,
                    subdistrict_id,
                    address_notes
                },
                res => {
                    message.success(res.data.message);
                    this.loadUserAddresses();
                    this.setState({
                        showAddressForm: false,
                        ...this.emptyAddressForm
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.hideLoader();
                }
            );
        }
    };

    removeAddress = (user_address_id) => {
        this.showLoader('Deleting address..');

        apiCall(
            USER_ADDRESS,
            'delete',
            {
                user_address_id
            },
            res => {
                message.success(res.data.message);
                this.loadUserAddresses();
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        );
    }

    setAddressAsPrimary = (user_address_id) => {
        this.showLoader('Setting address as primary..');

        apiCall(
            USER_ADDRESS_SET_PRIMARY,
            'post',
            {
                user_address_id
            },
            res => {
                message.success(res.data.message);
                this.loadUserAddresses();
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        );
    }

    renderUserAddresses = addresses => {
        return addresses.map(address => {
            return (
                <div
                    className={`${styles.userAddressItem} ${this.state.selectedUserAddressId === address.id ? styles.active : ''}`}
                    key={`UserAddress${address.id}`}
                    onClick={() => {
                        // this.setState({
                        //     selectedUserAddressId: address.id
                        // });
                    }}
                >
                    {this.state.selectedUserAddressId === address.id && (
                        <i className="fa fa-check-circle-o" />
                    )}
                    <div className={styles.userAddressLabel}>{address.label} {address.is_primary === 1 ? '(Primary)' : ''}</div>
                    <div className={styles.userAddressName}>{address.recipient_name} ({address.recipient_phone})</div>
                    <div className={styles.userAddressStreet}>
                        {address.address_street}<br/>
                        {address.subdistrict.subdistrict_name}, {address.city.type} {address.city.city_name},<br/>
                        {address.province.province_name}, Indonesia
                    </div>

                    {!empty(address.notes) && (
                        <div className={styles.userAddressNotes}>
                            Notes: {address.notes}
                        </div>
                    )}

                    <div style={{marginTop: 10, textAlign: 'right'}}>
                        {address.is_primary !== 1 && (
                            <button
                               style={{
                                   marginLeft: 15
                               }}
                               className={'btn-link'}
                               onClick={() => {this.setAddressAsPrimary(address.id)}}>Set as Primary</button>
                        )}

                        <button
                           style={{marginLeft: 15}}
                           className={'btn-link'}
                           onClick={() => {this.editAddress(address)}}>Edit</button>

                        {address.is_primary !== 1 && (
                            <button
                               style={{marginLeft: 15}}
                               className={'btn-link'}
                               onClick={() => {this.removeAddress(address.id)}}>Remove</button>
                        )}
                    </div>
                </div>
            );
        });
    };

    render() {
        const {
            pageLoading,
            showAddressForm,
            addressFormMode,

            user_addresses,
            provinces,
            cities,
            subdistricts,


            address_label,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes,
        } = this.state;

        return (
            <div>
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <AccountMenu activeMenu="shipping-address" isMobile={true}/>

                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <AccountMenu activeMenu="shipping-address"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        {/*<div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>Hello, MARK JECNO !</p>
                                            <p>From your My Account Dashboard you have the ability to view a snapshot of
                                                your recent account activity and update your account information. Select
                                                a link below to view or edit information.</p>
                                        </div>*/}
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2 style={{marginTop: 0, marginBottom: 10}}>Shipping Addresses</h2>
                                            </div>
                                            <Spin spinning={pageLoading} tip="Loading..">

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="box">
                                                            <div className="box-content">
                                                                {empty(user_addresses) && (
                                                                    <span>No address saved yet, please add a new address</span>
                                                                )}

                                                                {!empty(user_addresses) && (
                                                                    <div className={styles.userAddressList}>
                                                                        {this.renderUserAddresses(user_addresses)}
                                                                    </div>
                                                                )}

                                                                {!showAddressForm && (
                                                                    <div style={{marginTop: 30}}>
                                                                        <button
                                                                            className="btn btn-theme"
                                                                            onClick={this.onAddNewAddressBtnClicked}
                                                                        >
                                                                            Add New Address
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showAddressForm && (
                                                        <div className="col-sm-6">
                                                            <div className="box" style={{marginTop: 20}}>
                                                                <div className="box-title">
                                                                    <h3>{addressFormMode === 'new' ? 'Add New Address' : 'Edit Address'}</h3>
                                                                    {/*<a href="#">Edit</a>*/}
                                                                </div>
                                                                <div className="box-content">
                                                                    <div className={styles.formItem}>
                                                                        <label>Address Label</label>
                                                                        <Input placeholder="E.g. My Home, My Office, etc.."
                                                                               value={address_label}
                                                                               onChange={this.onChangeAddressLabel}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Recipient Name</label>
                                                                        <Input placeholder="Name.."
                                                                               value={recipient_name}
                                                                               onChange={this.onChangeRecipientName}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Recipient Phone</label>
                                                                        <Input placeholder="Phone number.."
                                                                               value={recipient_phone}
                                                                               onChange={this.onChangeRecipientPhone}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Address Street</label>
                                                                        <Input.TextArea
                                                                            placeholder="Street name & no.."
                                                                            rows={4}
                                                                            value={address_street}
                                                                            onChange={this.onChangeAddressStreet}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Province</label>
                                                                        <br/>
                                                                        <Select
                                                                            showSearch
                                                                            style={{width: '100%'}}
                                                                            placeholder="Select a province"
                                                                            optionFilterProp="children"
                                                                            onChange={this.onChangeProvince}
                                                                            // filterOption={(input, option) =>
                                                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            // }
                                                                            value={province_id}
                                                                        >
                                                                            {provinces.map(row => {
                                                                                return (
                                                                                    <Select.Option value={row.id} key={`Province${row.id}`}>
                                                                                        {row.province_name}
                                                                                    </Select.Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>City</label>
                                                                        <br/>
                                                                        <Select
                                                                            disabled={empty(province_id)}
                                                                            showSearch
                                                                            style={{width: '100%'}}
                                                                            placeholder="Select a city"
                                                                            optionFilterProp="children"
                                                                            onChange={this.onChangeCity}
                                                                            // filterOption={(input, option) =>
                                                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            // }
                                                                            value={city_id}
                                                                        >
                                                                            {cities.map(row => {
                                                                                return (
                                                                                    <Select.Option value={row.id} key={`City${row.id}`}>
                                                                                        {row.type} {row.city_name}
                                                                                    </Select.Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Subdistrict</label>
                                                                        <br/>
                                                                        <Select
                                                                            disabled={empty(city_id)}
                                                                            showSearch
                                                                            style={{width: '100%'}}
                                                                            placeholder="Select a subdistrict"
                                                                            optionFilterProp="children"
                                                                            onChange={this.onChangeSubdistrict}
                                                                            // filterOption={(input, option) =>
                                                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            // }
                                                                            value={subdistrict_id}
                                                                        >
                                                                            {subdistricts.map(row => {
                                                                                return (
                                                                                    <Select.Option value={row.id} key={`Subdistrict${row.id}`}>
                                                                                        {row.subdistrict_name}
                                                                                    </Select.Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Zipcode</label>
                                                                        <Input placeholder="Zipcode.."
                                                                               value={recipient_zip}
                                                                               onChange={this.onChangeRecipientZip}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        <label>Delivery Notes</label>
                                                                        <Input.TextArea
                                                                            placeholder="Your address / delivery notes.."
                                                                            rows={4}
                                                                            value={address_notes}
                                                                            onChange={this.onChangeAddressNotes}
                                                                        />
                                                                    </div>

                                                                    <div className={styles.formItem}>
                                                                        {addressFormMode === 'new' && (
                                                                            <button
                                                                                onClick={this.onSubmitNewAddress}
                                                                                className="btn btn-theme"
                                                                                style={{marginRight: 20}}
                                                                            >
                                                                                Save This Address
                                                                            </button>
                                                                        )}

                                                                        {addressFormMode === 'edit' && (
                                                                            <button
                                                                                onClick={this.onSubmitEditAddress}
                                                                                className="btn btn-theme"
                                                                                style={{marginRight: 20}}
                                                                            >
                                                                                Edit Address
                                                                            </button>
                                                                        )}

                                                                        <button
                                                                            onClick={this.onCancelNewAddressForm}
                                                                            className="btn btn-classic"
                                                                            style={{marginRight: 20}}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Spin>
                                            {/*<div>
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3>Address Book</h3>
                                                        <a href="#">Manage Addresses</a>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h6>Default Billing Address</h6>
                                                            <address>
                                                                You have not set a default billing address.<br/>
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h6>Default Shipping Address</h6>
                                                            <address>
                                                                You have not set a default shipping address.<br />
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default AccountShippingAddress