import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './StoresOnlineDelivery.module.scss';
import ModuleNewsletter from "./ModuleNewsletter";

class StoresPickupOnStore extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="HAMMER was launched as the first brand of PT WARNA MARDHIKA in 1987. With characteristic lines, varying colors and bold, making HAMMER very famous among celebrities, models, and Indonesians."/>
                </Helmet>

                <section className={styles.coverSection}>
                    <img alt={"pickup on store"} src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-pickuponstore.jpg`} />
                </section>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>Store Pickup</h1>
                </section>

                <section className={styles.stepSection}>
                    <div className={styles.stepWord}>STEP</div>
                    <div className={styles.stepItems}>
                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                1
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Checkout
                                </div>
                                <div className={styles.desc}>
                                    Masukan produk favoritmu ke dalam keranjang lalu checkout<br/>
                                    Sign In to process. Apabila kamu belum mempunyai akun silahkan untuk Sign Up terlebih dahulu<br/>
                                    Lalu klik Checkout
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                2
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Choose A Store
                                </div>
                                <div className={styles.desc}>
                                    Pilih Store Pickup<br/>
                                    Pilih toko dimana kamu akan mengambil barangmu
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                3
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Payment
                                </div>
                                <div className={styles.desc}>
                                    Klik Go To Payment<br/>
                                    Checkout dan bayar pesananmu melalui berbagai chanel pembayaran
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                4
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Purchase Success
                                </div>
                                <div className={styles.desc}>
                                    Setelah selesai melakukan pembayaran kamu akan mendapatkan tanda terima dan KODE PICK UP melalui email atau dengan cek menu My Order di Profil Account<br/>
                                    Kasir akan menyiapkan produkmu minimal 1 jam setelah pembayaran berhasil<br/>
                                    <img alt={"how to pickup on store"} src={`${process.env.PUBLIC_URL}/assets/images/wm/howto-pickuponstore-1.jpg`} /> <br/>
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                5
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Pickup at Store
                                </div>
                                <div className={styles.desc}>
                                    Tunjukan Kode Pick Up pada kasir<br/>
                                    Kasir akan melakukan verifikasi pada system dan menyerahkan pesanan<br/>
                                    <img alt={"kode pickup"} src={`${process.env.PUBLIC_URL}/assets/images/wm/howto-pickuponstore-2.jpg`} /> <br/>
                                    Selamat berbelanja
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />
            </React.Fragment>
        );
    }
}

export default StoresPickupOnStore;