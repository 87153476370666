import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import moment from "moment";

moment.updateLocale('id', {
    relativeTime: {
        future: "%s mendatang",
        past: "%s yang lalu",
        s: function (number, withoutSuffix, key, isFuture) {
            return '00:' + (number < 10 ? '0' : '')
                + number + ' menit';
        },
        m: "01:00 menit",
        mm: function (number, withoutSuffix, key, isFuture) {
            return (number < 10 ? '0' : '')
                + number + ':00 menit';
        },
        h: "an jam",
        hh: "%d jam",
        d: "a hari",
        dd: "%d hari",
        M: "a bulan",
        MM: "%d bulan",
        y: "a tahun",
        yy: "%d tahun"
    }
});

// import 'antd/dist/antd.css';

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
