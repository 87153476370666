import React, {Component} from 'react';
import {connect} from 'react-redux';

import {getBestSeller} from "../../services";
import {addToCart, addToCompare, addToWishlist} from "../../actions";
import styles from './RelatedProduct.module.scss';
import ProductListItem from "../collection/common/product-list-item";


class RelatedProduct extends Component {
    render (){
        const {symbol, addToCart, addToWishlist, addToCompare} = this.props;
        const { products } = this.props;

        return (
            <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 product-related">
                            <h2 style={{
                                fontFamily: "\"Open Sans\", sans-serif",
                                textTransform: 'none'
                            }}
                                className={styles.title}
                            >Related Products</h2>
                        </div>
                    </div>
                    <div className="row search-product">
                        { products.map((product, index ) =>
                            <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                                <ProductListItem product={product}
                                                 symbol={symbol}
                                                 onAddToCompareClicked={() => addToCompare(product)}
                                                 onAddToWishlistClicked={() => addToWishlist(product)}
                                                 onAddToCartClicked={addToCart}
                                                 key={index}
                                />
                                {/*<ProductItem product={product} symbol={symbol}
                                             onAddToCompareClicked={() => addToCompare(product)}
                                             onAddToWishlistClicked={() => addToWishlist(product)}
                                             onAddToCartClicked={() => addToCart(product, 1)} key={index} />*/}
                            </div>)
                        }
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(RelatedProduct);
