import React, {Component} from 'react';
import {Modal, Spin} from "antd";
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {USER_ORDERS} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import AccountMenu from "./atoms/AccountMenu";
import styles from "./AccountOrder.module.scss";
import moment from "moment";
import {
    MOMENT_READABLE_DATE_FORMAT,
    MOMENT_SQL_DATETIME_FORMAT,
    SHIPPING_TYPE_ONLINE,
    SHIPPING_TYPE_PICKUP
} from "../utils/constants";
import {number_format} from "../helpers/stringHelper";
import {PRIMARY} from "../utils/colors";
import DeliveryTimeline from "./wm/DeliveryTimeline";
import {MapPin} from "lucide-react";

class AccountOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            orders: null,
            selectedTrx: null,
            selectedTrackingTrx: null
        };
    }

    componentDidMount() {
        this.loadOrders();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    loadOrders = () => {
        this.showLoader('Loading your orders..');
        apiCall(
            USER_ORDERS,
            'get',
            null,
            res => {
                this.setState({
                    orders: res.data.user_orders
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    openTrxDetails = salesTrx => {
        this.setState({
            selectedTrx: salesTrx
        });
    };

    closeTrxDetails = () => {
        this.setState({
            selectedTrx: null
        });
    };

    openTrxTracking = salesTrx => {
        this.setState({
            selectedTrackingTrx: salesTrx
        });
    };

    closeTrxTracking = () => {
        this.setState({
            selectedTrackingTrx: null
        });
    };

    renderOrders = orders => {
        return orders.map(order => {
            return (
                <div
                    className={`${styles.orderItem}`}
                    key={`UserOrder${order.id}`}
                    onClick={() => {
                        // this.setState({
                        //     selectedUserAddressId: address.id
                        // });
                    }}
                >
                    <div style={{float: 'right', fontSize: '12px', fontWeight: 'bold'}}>{moment(order.trx_date, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</div>
                    <div style={{
                        fontSize: '12px',
                        color: '#888'
                    }}
                    >ORDER #{order.trx_ref.toUpperCase().substr(0, 8)}</div>
                    <div style={{
                        fontSize: '20px',
                        color: PRIMARY,
                        margin: '8px 0'
                    }}><strong>IDR {number_format(order.grand_total, 0)}</strong></div>

                    <div className={styles.orderItemName}>Shipping: {order.shipping_type}</div>
                    <div className={styles.orderItemName}>Status: {order.trx_status}</div>

                    {order.shipping_type === SHIPPING_TYPE_PICKUP && (
                        <div className={styles.orderItemName}>Outlet: {order.showroom?.showroom_name}</div>
                    )}

                    {!empty(order.notes) && (
                        <div className={styles.orderItemNotes}>
                            Notes: {order.notes}
                        </div>
                    )}

                    <div style={{marginTop: 10}}>
                        <button className={"btn-link"}
                           onClick={() => {this.openTrxDetails(order)}}>Lihat Detail</button>
                        {order.shipping_type === SHIPPING_TYPE_ONLINE && !empty(order.no_resi) && (
                            <>
                                &nbsp;&nbsp;&nbsp;
                                <button className={"btn-link"}
                                   onClick={() => {this.openTrxTracking(order)}}>Order Tracking</button>
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };

    render() {
        const {
            pageLoading,
            orders,
            selectedTrx,
            selectedTrackingTrx
        } = this.state;

        return (
            <div>
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <AccountMenu activeMenu="orders" isMobile={true}/>

                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true" /> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <AccountMenu activeMenu="orders"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2 style={{marginTop: 0, marginBottom: 10}}>My Orders</h2>
                                            </div>
                                            <Spin spinning={pageLoading} tip="Loading..">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="box">
                                                            <div className="box-content">
                                                                {empty(orders) && (
                                                                    <span>No orders yet, shop more with us!</span>
                                                                )}

                                                                {!empty(orders) && (
                                                                    <div className={styles.orderList}>
                                                                        {this.renderOrders(orders)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {!empty(selectedTrx) && (
                    <Modal
                        title={`ORDER #${selectedTrx.trx_ref.toUpperCase().substr(0, 8)}`}
                        visible={true}
                        onOk={this.closeTrxDetails}
                        onCancel={this.closeTrxDetails}
                        footer={null}
                        width={1000}
                    >
                        <div className="container">
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <h6>Kepada Yth:<br/>Bapak/Ibu <strong>{selectedTrx.user?.name || 'GUEST'}</strong></h6>
                                    <br/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Order Information</h4>
                                    <hr/>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Order No</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>#{selectedTrx.trx_ref.toUpperCase().substr(0, 8)}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Date</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{moment(selectedTrx.trx_date, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.trx_status}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <section className="cart-section section-b-space" style={{paddingTop: 50}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Product List</h4>
                                        <hr/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className={`table cart-table table-responsive-xs ${styles.cartTable}`}>
                                            <thead>
                                            <tr className="table-head">
                                                <th scope="col">&nbsp;</th>
                                                <th scope="col">product name</th>
                                                <th scope="col">Size</th>
                                                <th scope="col">price</th>
                                                <th scope="col">quantity</th>
                                                <th scope="col">total</th>
                                            </tr>
                                            </thead>
                                            {selectedTrx.sales_trx_details.map((detail, index) => {
                                                const retailPrice = parseInt(detail.retail_price);
                                                const netPrice = parseInt(detail.net_price);
                                                const directDiscPercent = detail.disc_percent;
                                                const directDiscNominal = detail.disc_nominal;
                                                const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;

                                                return (
                                                    <tbody key={index}>
                                                    <tr>
                                                        <td>
                                                            <img style={{maxWidth: '100%', width: 100}} src={detail.product_detail?.product_images[0]?.image_url} alt=""/>
                                                        </td>
                                                        <td>
                                                            {detail.product_name}
                                                            <div className="mobile-cart-content row">
                                                                <div className="col-xs-3">
                                                                    <div className="qty-box">
                                                                        {detail.qty}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-3">
                                                                    <h2 className="td-color">IDR {number_format(detail.subtotal, 0)}</h2>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h2 style={{
                                                                fontSize: 16
                                                            }}
                                                            >
                                                                {detail.size_code}
                                                            </h2>
                                                        </td>
                                                        <td>
                                                            <h2 style={{
                                                                fontSize: 16
                                                            }}
                                                            >
                                                                {retailPrice !== grandPrice && (
                                                                    <div style={{marginBottom: 5}}>
                                                                        <del><span className="money">IDR {number_format(retailPrice, 0)}</span></del>
                                                                    </div>
                                                                )}
                                                                IDR {number_format(grandPrice, 0)}
                                                            </h2>
                                                        </td>
                                                        <td>
                                                            <div className="qty-box">
                                                                {detail.qty}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h2 style={{
                                                                    fontSize: 16,
                                                                    color: '#222',
                                                                    fontWeight: 700
                                                                }}
                                                                className="td-color"
                                                            >
                                                                IDR {number_format(detail.subtotal, 0)}
                                                            </h2>
                                                        </td>
                                                    </tr>
                                                    </tbody>)
                                            })}
                                        </table>
                                        <table className="table cart-table table-responsive-md">
                                            <tfoot>
                                            {selectedTrx.shipping_type === SHIPPING_TYPE_ONLINE && (
                                                <tr>
                                                    <td style={{width: 'auto'}}>Shipping Cost ({`${selectedTrx.shipping_courier} - ${selectedTrx.shipping_service_name}`})</td>
                                                    <td style={{
                                                        width: 300,
                                                        fontSize: 16,
                                                        color: '#222',
                                                        fontWeight: 500,
                                                        textAlign: 'right',
                                                        paddingRight: '30px'
                                                    }}>
                                                        IDR {number_format(selectedTrx.shipping_cost, 0)}
                                                    </td>
                                                </tr>
                                            )}

                                            {selectedTrx.shipping_type === SHIPPING_TYPE_PICKUP && (
                                                <tr>
                                                    <td style={{width: 'auto'}}>Shipping Cost (Self Pickup at {selectedTrx.showroom?.showroom_name})</td>
                                                    <td style={{
                                                        width: 300,
                                                        fontSize: 16,
                                                        color: '#222',
                                                        fontWeight: 500,
                                                        textAlign: 'right',
                                                        paddingRight: '30px'
                                                    }}>
                                                        IDR {number_format(selectedTrx.shipping_cost, 0)}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td style={{width: 'auto'}}>Grand Total</td>
                                                <td style={{width: 300, textAlign: 'right', paddingRight: '30px'}}>
                                                    <h2>IDR {number_format(selectedTrx.grand_total, 0)} </h2>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Shipping</h4>
                                    <hr/>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Shipping Type</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.shipping_type}</td>
                                        </tr>
                                        {selectedTrx.shipping_type === SHIPPING_TYPE_PICKUP && (
                                            <>
                                                <tr>
                                                    <td>Pick Up Location</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.showroom?.showroom_name}</td>
                                                </tr>
                                                {!empty(selectedTrx.pickup_code) && (
                                                    <tr>
                                                        <td>Pick Up Code</td>
                                                        <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                        <td style={{fontWeight: 'bold', fontSize: '20px'}}>{selectedTrx.pickup_code}</td>
                                                    </tr>
                                                )}
                                            </>
                                        )}

                                        {selectedTrx.shipping_type === SHIPPING_TYPE_ONLINE && (
                                            <>
                                                <tr>
                                                    <td>Courier</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{`${selectedTrx.shipping_courier} - ${selectedTrx.shipping_service_name}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cost</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{`IDR ${number_format(selectedTrx.shipping_cost, 0)}`}</td>
                                                </tr>

                                                <tr>
                                                    <td>Recipient Name</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.address_recipient_name ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Recipient Phone</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.address_recipient_phone ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address Name</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.address_label ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.address_street ?? '-'}, {selectedTrx.address_subdistrict ?? '-'}, {selectedTrx.address_city ?? '-'}, {selectedTrx.address_province ?? '-'}</td>
                                                </tr>

                                                <tr>
                                                    <td>Notes</td>
                                                    <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                    <td>{selectedTrx.shipping_notes ?? '-'}</td>
                                                </tr>

                                                {!empty(selectedTrx.no_resi) && (
                                                    <tr>
                                                        <td>No. Resi</td>
                                                        <td style={{width: 50, textAlign: 'center'}}>:</td>
                                                        <td style={{fontWeight: 'bold', fontSize: '20px'}}>{selectedTrx.no_resi}</td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <br/><br/>

                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Payment</h4>
                                    <hr/>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Payment Status</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.payment_status_desc ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Channel</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.payment_channel ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Due Date</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.payment_duedate ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Date</td>
                                            <td style={{width: 50, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrx.payment_date ?? '-'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <br/><br/>
                    </Modal>
                )}

                {!empty(selectedTrackingTrx) && (
                    <Modal
                        title={`ORDER #${selectedTrackingTrx.trx_ref.toUpperCase().substr(0, 8)} TRACKING`}
                        visible={true}
                        onOk={this.closeTrxTracking}
                        onCancel={this.closeTrxTracking}
                        footer={null}
                        width={800}
                    >
                        <div className="container">
                            <div className={styles.trackingLocation}>
                                <div className={"text-center"}>
                                    <MapPin size={32} style={{marginBottom: '8px'}} color="#e74c3c" />

                                    <br/>
                                    Asal<br/>
                                    <strong>{selectedTrackingTrx?.order_tracking?.sender_address}</strong>
                                </div>
                                <div className={styles.separator} />
                                <div className={"text-center"}>
                                    <MapPin size={32} style={{marginBottom: '8px'}} color="#e74c3c" />
                                    <br/>
                                    Tujuan<br/>
                                    <strong>{selectedTrackingTrx?.order_tracking?.receiver_address}</strong>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <h4>Last Status</h4>
                                    <hr/>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Status</td>
                                            <td style={{width: 30, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrackingTrx?.order_tracking?.last_status?.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td style={{width: 30, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrackingTrx?.order_tracking?.last_status?.date_time}</td>
                                        </tr>
                                        <tr>
                                            <td>Receiver Name</td>
                                            <td style={{width: 30, textAlign: 'center'}}>:</td>
                                            <td>{selectedTrackingTrx?.order_tracking?.last_status?.receiver_name}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <br/><br/>

                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Tracking History</h4>
                                    <hr/>
                                    <ul className={styles.trackingHistory}>
                                        {!empty(selectedTrackingTrx?.order_tracking?.track_history) && (
                                            <DeliveryTimeline data={
                                                selectedTrackingTrx.order_tracking.track_history.toReversed().map((row, index) => {
                                                    return {
                                                        time: moment(row.date_time).format('dddd, DD MMM YYYY, HH:mm'),
                                                        description: `${row.city ? row.city : ''} ${row.receiver_name ? row.receiver_name : ''}`,
                                                        status: row.status,
                                                    };
                                                })
                                            }/>
                                        )}
                                        {/*{selectedTrackingTrx?.order_tracking?.track_history?.reverse().map((row, index) => {*/}
                                        {/*    return (*/}
                                        {/*        // <DeliveryTimeline />*/}
                                        {/*        <li key={`TrackingHistory${index}`}>*/}
                                        {/*            {row.date_time}<br/>*/}
                                        {/*            [{row.status}] {row.city} {row.receiver_name}*/}
                                        {/*        </li>*/}
                                        {/*    );*/}
                                        {/*})}*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

            </div>
        )
    }
}

export default AccountOrders