import {empty} from "../helpers/generalHelper";
import uuid from "uuid/v4";

export const APP_VERSION = '1.1.4';
export const BUILD_NUMBER = 5;
export const BRAND_CODE = 'HM';

export const PAGE_TITLE_GENERAL = 'Hammer | PT Warna Mardhika';
export const PAGE_DESCRIPTION_GENERAL = 'HAMMER was launched as the first brand of PT WARNA MARDHIKA in 1987. With characteristic lines, varying colors and bold, making HAMMER very famous among celebrities, models, and Indonesians.';

export const API_BASE_URL = 'https://api.warna-mardhika.com/'; /*(window.location.host === 'hammer-clothing.com' || window.location.host === 'www.hammer-clothing.com') ? 'https://api.warna-mardhika.com/' : (window.location.host === 'ecom.warna-mardhika.com' ? 'https://dev-api.coconutisland75.com/' : 'http://wm-ecommerce-api.test/');*/
export const FASPAY_XPRESS_URL = (window.location.host === 'hammer-clothing.com' || window.location.host === 'www.hammer-clothing.com') ? 'https://xpress.faspay.co.id/v3/payment' : (window.location.host === 'ecom.warna-mardhika.com' ? 'https://dev.faspay.co.id/xpress/payment' : 'https://dev.faspay.co.id/xpress/payment');
export const IMAGE_BASE_URL = 'https://office.pemudateochewindonesia.com/';

export const MOMENT_READABLE_DATE_FORMAT = 'D MMM YYYY';
// export const MOMENT_READABLE_DATETIME_FORMAT = 'D MMM YYYY, HH:mm';
export const MOMENT_SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const PROMO_TYPE_GROUP = "GROUP";
export const PROMO_TYPE_SUB_GROUP = "SUB_GROUP";
export const PROMO_TYPE_PRODUCT = "PRODUCT";

export const SHIPPING_TYPE_ONLINE = "ONLINE_DELIVERY";
export const SHIPPING_TYPE_PICKUP = "PICKUP_ON_STORE";

export const LOCAL_DEVICE_ID = () => {
    let local_device_id = localStorage.getItem('local_device_id');
    if(empty(local_device_id)){
        local_device_id = uuid();
        localStorage.setItem('local_device_id', local_device_id);
    }

    return local_device_id;
};