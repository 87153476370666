import React, {Component} from 'react';
import {empty} from "../../helpers/generalHelper";
import {message} from "antd";
import {apiCall} from "../../helpers/networkHelper";
import {DELETE_ACCOUNT} from "../../utils/endpoints";
import ErrorHandler from "../../classes/ErrorHandler";
import {connect} from "react-redux";
import {signIn} from "../../actions";

class DeleteAccount extends Component {

    constructor (props) {
        super (props)

        this.state = {
            email: '',
            password: ''
        };
    }

    onChangeEmail = e => {
        this.setState({email: e.target.value});
    };

    onChangePassword = e => {
        this.setState({password: e.target.value});
    };

    onSubmit = e => {
        e.stopPropagation();
        e.preventDefault();

        if(!window.confirm('Are you sure you want to delete your account? This action is irreversible.')){
            return;
        }

        const {
            email,
            password
        } = this.state;
        let validForm = true;

        if(empty(email)){
            message.error('Please enter your email address');
            validForm = false;
        }

        if(empty(password)){
            message.error('Please enter your password');
            validForm = false;
        }

        const formData ={
            email,
            password
        };

        if(validForm){
            apiCall(
                DELETE_ACCOUNT,
                'post',
                formData,
                (res) => {
                    message.success('Your account has been successfully deleted.');

                    setTimeout(() => {
                        window.location.href = '/';
                    }, 3000);
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }
            );
        }

    };

    render (){
        const {email, password} = this.state;

        return (
            <div>
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Delete Account</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.onSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="email"
                                                   placeholder="Email"
                                                   value={email}
                                                   onChange={this.onChangeEmail}
                                                   required={true} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password"
                                                   className="form-control"
                                                   id="password"
                                                   placeholder="Enter your password"
                                                   value={password}
                                                   onChange={this.onChangePassword}
                                                   required={true} />
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-solid">Delete</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {signIn}
)(DeleteAccount)