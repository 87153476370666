import React, {Component} from 'react';
import {empty} from "../../helpers/generalHelper";
import {message} from 'antd';
import {apiCall} from "../../helpers/networkHelper";
import {SIGNUP} from "../../utils/endpoints";
import ErrorHandler from "../../classes/ErrorHandler";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {signIn} from "../../actions";

class Register extends Component {

    constructor (props) {
        super (props);

        this.state = {
            name: '',
            gender: '',
            email: '',
            password: '',
            password_conf: ''
        };
    }

    onChangeName = e => {
        this.setState({name: e.target.value});
    };

    onChangeGender = e => {
        this.setState({gender: e.currentTarget.value});
    };

    onChangeEmail = e => {
        this.setState({email: e.target.value});
    };

    onChangePassword = e => {
        this.setState({password: e.target.value});
    };

    onChangePasswordConf = e => {
        this.setState({password_conf: e.target.value});
    };

    onSubmitSignUp = e => {
        e.stopPropagation();
        e.preventDefault();

        const {
            name,
            gender,
            email,
            password,
            password_conf
        } = this.state;
        let validForm = true;

        if(empty(name)){
            message.error('Please enter your name');
            validForm = false;
        }

        if(empty(gender)){
            message.error('Please choose your gender');
            validForm = false;
        }

        if(empty(email)){
            message.error('Please enter your email address');
            validForm = false;
        }

        if(empty(password)){
            message.error('Please enter your password, 8 characters minimum');
            validForm = false;
        }else{
            if(password.length < 8){
                message.error('Password length should be at least 8 characters');
                validForm = false;
            }
        }

        if(empty(password_conf)){
            message.error('Please enter the password confirmation');
            validForm = false;
        }

        if(password !== password_conf){
            message.error('Your password confirmation doesn\'t match the password');
            validForm = false;
        }

        const formData ={
            name,
            gender,
            email,
            password,
            password_conf
        };

        if(validForm){
            apiCall(
                SIGNUP,
                'post',
                formData,
                (res) => {
                    message.success('You have successfully created an account, feel free to shop now.');
                    localStorage.setItem('access_token', res.data.access_token);
                    this.props.signIn();

                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);

                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }
            );
        }

    };

    render (){
        const {
            name,
            email,
            password,
            password_conf
        } = this.state;

        return (
            <div>
                {/*<Breadcrumb title={'create account'}/>*/}

                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Sign Up</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.onSubmitSignUp}>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Name</label>
                                                <input type="text"
                                                       className="form-control"
                                                       id="fname"
                                                       placeholder="Enter your name"
                                                       value={name}
                                                       onChange={this.onChangeName}
                                                       required />

                                                <label htmlFor="review">Gender</label>
                                                <div style={{marginBottom: 30, marginTop: 10}}>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               style={{marginBottom: 0}}
                                                               name="gender"
                                                               id="genderMALE"
                                                               value="MALE"
                                                               onChange={this.onChangeGender}
                                                               required
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="genderMALE">MALE</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               style={{marginBottom: 0}}
                                                               name="gender"
                                                               id="genderFEMALE"
                                                               value="FEMALE"
                                                               onChange={this.onChangeGender}
                                                               required
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="genderFEMALE">FEMALE</label>
                                                    </div>
                                                </div>

                                                <label htmlFor="email">Email</label>
                                                <input type="text"
                                                       className="form-control"
                                                       id="email"
                                                       placeholder="Enter your email address"
                                                       value={email}
                                                       onChange={this.onChangeEmail}
                                                       required="" />

                                                <label htmlFor="password">Password</label>
                                                <input type="password"
                                                       className="form-control"
                                                       id="password"
                                                       placeholder="Enter your password"
                                                       value={password}
                                                       onChange={this.onChangePassword}
                                                       required="" />

                                                <label htmlFor="password_conf">Password Confirmation</label>
                                                <input type="password"
                                                       className="form-control"
                                                       id="password_conf"
                                                       placeholder="Re-enter your password"
                                                       value={password_conf}
                                                       onChange={this.onChangePasswordConf}
                                                       required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <button type="submit" className="btn btn-solid">Create Account</button>
                                            <Link to="/sign-in" style={{marginLeft: 15, lineHeight: '45px'}}>Have account? Sign in.</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {signIn}
)(Register)