import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {decrementQty, incrementQty, removeFromCart, retrieveCart} from '../actions'
import {PAGE_DESCRIPTION_GENERAL, PAGE_TITLE_GENERAL} from "../utils/constants";
import {apiCall} from "../helpers/networkHelper";
import {CHECKOUT_PAYMENT} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {Loader} from "react-overlay-loader";

class PaymentResponse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            loadingText: "",
            availabilityCheckFailed: false,
        };
    }

    componentDidMount() {
        this.confirmPayment();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    confirmPayment = () => {
        const urlParams = new URLSearchParams(window.location.search);

        this.showLoader();
        apiCall(
            CHECKOUT_PAYMENT,
            'post',
            {
                trx_id: urlParams.get('trx_id'),
                merchant_id: urlParams.get('merchant_id'),
                merchant: urlParams.get('merchant'),
                bill_no: urlParams.get('bill_no'),
                bill_ref: urlParams.get('bill_ref'),
                bill_total: urlParams.get('bill_total'),
                payment_reff: urlParams.get('payment_reff'),
                payment_date: urlParams.get('payment_date'),
                bank_user_name: urlParams.get('bank_user_name'),
                status: urlParams.get('status'),
                signature: urlParams.get('signature')
            },
            res => {
                console.log(res.data);
            },
            err => {
                this.setState({availabilityCheckFailed: true});
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    render() {
        const {
            pageLoading,
            loadingText
        } = this.state;


        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>{PAGE_TITLE_GENERAL}</title>
                    <meta name="description"
                          content={PAGE_DESCRIPTION_GENERAL}/>
                </Helmet>
                {/*SEO Support End */}

                <Loader fullPage loading={pageLoading} text={loadingText}/>

                <h1 style={{
                    textAlign: 'center',
                    fontFamily: "\"Open Sans\", sans-serif",
                    marginTop: 50,
                    marginBottom: 50,
                    fontSize: '40px',
                    fontWeight: 700
                }}
                >Order Success</h1>

                <div className="container" style={{marginBottom: 50}}>
                    <div className="row">
                        <div className="col-md-12">
                            {this.props.authenticated && (
                                <>
                                    <h3 style={{textAlign: 'center'}}>Thank you for shopping!</h3>

                                    <div style={{textAlign: 'center', marginTop: 30}}>
                                        <Link to="/account/orders">
                                            <button className="btn btn-solid">Check My Order Status</button>
                                        </Link>
                                    </div>
                                </>
                            )}

                            {!this.props.authenticated && (
                                <>
                                    <h3 style={{textAlign: 'center'}}>Thank you for shopping, please check your email for further information.</h3>

                                    <div style={{textAlign: 'center', marginTop: 30}}>
                                        <Link to="/products">
                                            <button className="btn btn-solid">Continue Shopping</button>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, retrieveCart}
)(PaymentResponse)