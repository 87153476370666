import React, {Component} from 'react';
import {Spin} from "antd";
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {WISHLIST} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import AccountMenu from "./atoms/AccountMenu";
import ProductListItem from "./collection/common/product-list-item";
import {connect} from "react-redux";
import {addToCart, addToCompare, addToWishlist} from "../actions";

class AccountWishlist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            wishlists: null,
        };
    }

    componentDidMount() {
        this.loadWishlists();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    loadWishlists = () => {
        this.showLoader('Loading your orders..');
        apiCall(
            WISHLIST,
            'get',
            null,
            res => {
                const products = res.data.user_wishlists.map(row => {
                    const retailPrice = parseInt(row.retail_price);
                    const netPrice = parseInt(row.net_price);
                    // const directDiscPercent = !empty(row.direct_discount) ? parseFloat(row.direct_discount.direct_disc_percent) : 0;
                    // const directDiscNominal = !empty(row.direct_discount) ? parseInt(row.direct_discount.direct_disc_nominal) : 0;
                    // const grandPrice = (netPrice * ((100 - directDiscPercent) / 100)).toFixed(0) - directDiscNominal;
                    const grandPrice = netPrice;

                    let coverImage = row.product_images[0]?.image_url;
                    row.product_images.find(productImage => {
                        if(productImage.image_type === 'COVER'){
                            if(!empty(productImage.image_url)){
                                coverImage = productImage.image_url;
                            }
                        }

                        return true;
                    });

                    return {
                        ...row,
                        id: row.id,
                        name: row.product_name,
                        price: row.sizes[0].retail_price,
                        salePrice: row.sizes[0].net_price,
                        discount: Math.floor(row.sizes[0].net_price / row.sizes[0].retail_price),
                        retailPrice,
                        netPrice,
                        // directDiscPercent,
                        // directDiscNominal,
                        grandPrice,
                        coverImage,
                        pictures: row.product_images.map(product_image => product_image.image_url),
                        shortDetails: row.short_description,
                        description: row.detailed_description,
                        stock: 100,
                        new: false,
                        sale: false,
                        // category: "women"
                        // colors: (2) ["gray", "orange"]
                        size: row.sizes.map(size => size.size_code),
                        // tags: (2) ["caprese", "puma"]
                        rating: 5,
                        variants: row.sizes.map(size => {
                            return {
                                size: size.size_code,
                                images: !empty(row.product_images) ? row.product_images[0].image_url : ''
                            };
                        })
                    };
                });

                this.setState({
                    wishlists: products
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    render() {
        const {
            pageLoading,
            wishlists
        } = this.state;
        const {addToCart, symbol, addToWishlist, addToCompare} = this.props;
        const colSize = 3;

        return (
            <div>
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <AccountMenu activeMenu="wishlist" isMobile={true}/>

                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"/> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <AccountMenu activeMenu="wishlist"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2 style={{marginTop: 0, marginBottom: 40}}>My Wishlist</h2>
                                            </div>
                                            <Spin spinning={pageLoading} tip="Loading..">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="box">
                                                            <div className="box-content">
                                                                {empty(wishlists) && (
                                                                    <span>No wishlist yet, shop more with us!</span>
                                                                )}

                                                                {!empty(wishlists) && (
                                                                    <div className="product-wrapper-grid">
                                                                        <div className="container-fluid">
                                                                            <div className="row">
                                                                                {wishlists.map((product, index) =>
                                                                                    <div
                                                                                        className={`${colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + colSize}`}
                                                                                        style={{marginBottom: 30}}
                                                                                        key={index}>
                                                                                        <ProductListItem
                                                                                            product={product}
                                                                                            symbol={symbol}
                                                                                            onAddToCompareClicked={() => addToCompare(product)}
                                                                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                                                                            onAddToCartClicked={addToCart}
                                                                                            key={index}
                                                                                            noQuickBar={true}
                                                                                        />
                                                                                    </div>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol
});

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(AccountWishlist)